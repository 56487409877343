import React from 'react'
import styled from 'styled-components'
import '../../styles/icons/css/icons.css'
import { Link } from 'gatsby'
import PaymentSummaryTotal from './_PaymentSummaryTotal.js'
import ccInputs from './cc_inputs.png'
// 💅
const InvoicePaymentSummaryTwo = styled.div`
  // 📰
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
  "sg_title"
  "sg_invoices_selected"
  "sg_invoice_selected"
  "sg_invoice_total"
  "sg_select_payment_method"
  "sg_confirm_and_pay"
  "sg_btn_cancel"
  "sg_disclaimer";

  input.input-med{
    height: 36px;
  }

  label > span{
    color: inherit;
    font-weight: normal;
  }

  .form-group:not(:last-of-type){
    margin-bottom: 20px;
  }

  .SideNavTitle {
    grid-area: sg_title; // 🗺
    justify-self: start;
    align-self: start;
    font-family: 'Teko', sans-serif;
    text-transform: uppercase;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-bottom: 20px;
    font-weight: 400;
    line-height: 1.5;
    font-size: 30px;
    color: #141b1f;
    padding-top: 30px;
  }

  .invoiceTotalWrapper{
    display: flex;
    align-items: center;
    height: 50px;

    > div{
      width: 100%;
    }
  }

  .separate-items{
    display: flex;
    flex-direction: row;

    > *{
      flex: 1;

      &:not(:last-child){
        margin-right: 10px;
      }
    }
  }

  .InvoicesSelected {
    grid-area: sg_invoices_selected; // 🗺
    font-family: "Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 18px;
    font-weight: 400;
    color: #2b363b;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 15px 0;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
  }
  .InvoiceSelected {
    grid-area: sg_invoice_selected;
    background-color: #f7f7f7;
    border-radius: 3px;
    .InvoiceSelectedGrid {
      display: grid;
      grid-template-rows: 50px;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-areas:
      "sg_invoice_due sg_date sg_total";

      .SgInvoice {
        grid-area: sg_invoice_due;
        align-self: center;
        font-size: 12px;
        color: #141b1f;
        padding-left: 10px;
      }
      .SgDate {
        grid-area: sg_date;
        align-self: center;
        justify-self: center;
        font-size: 12px;
        color: #141b1f;
      }
      .SgTotal {
        grid-area: sg_total;
        justify-self: end;
        align-self: center;
        font-weight: 600;
        font-size: 15px;
        color: #141b1f;
        padding-right: 10px;
      }
      .exit-icon {
        top: 40px;
      }
    }
  }

  .SelectPaymentMethod {
    grid-area: sg_select_payment_method; // 🗺
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .ConfirmAndPay {
    grid-area: sg_confirm_and_pay; // 🗺
      .BtnConfirmAndPay {
        color: #fff;
      background-color: #3daf2c;
      border-color: #3daf2c;
      padding: 6px 16px;
      margin: 5px 0;
      height: 45px;
      text-transform: uppercase;
      border-radius: 3px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-family: "Open Sans";
      font-weight: 700;
      font-size: 14px;
      transition: background .125s ease,color .125s ease,border .125s ease;
      width: 100%;
      }
  }
  .BtnCancel {
    grid-area: sg_btn_cancel; // 🗺
        color: #0033a1;
      background-color: transparent;
      border-color: transparent;
      padding: 6px 16px;
      margin: 5px 0;
      height: 45px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-family: "Open Sans";
      font-weight: 700;
      font-size: 14px;
      text-align: center;
      justify-self: center;

      }
  .Disclaimer {
    grid-area: sg_disclaimer; // 🗺
    font-family: "Open Sans";
    font-weight: 400;
    margin-top: 10px;
    font-size: 10px;
    color: #a9adaf;
    line-height: 1.42857143;
      span {
        color: #0033a1;
      }
}
`;

// ⚛️
const InvoicePageComponent = (props) => {
  return (
    <InvoicePaymentSummaryTwo>
      <div className="SideNavTitle">Invoice Payment Summary</div>
      <div className="InvoicesSelected">1 Invoices Selected</div>
      <div className="InvoiceSelected">
        <div className="InvoiceSelectedGrid">
          <div className="SgInvoice">Invoice#<br /> Due</div>
          <div className="SgDate">33697917-1<br /> Apr 25</div>
          <div className="SgTotal">$1.50</div>
          {/* <ExitButton>
          <i class="fas fa-times exit-icon" />
          </ExitButton> */}
        </div>
      </div>
      <div className='invoiceTotalWrapper'>
        <PaymentSummaryTotal
          invoiceTotal="Invoice Total"
          invoiceAmount="$50.00"
        />
      </div>
      <div className="SelectPaymentMethod">
        <div className="form-group es-i-freight-class">
          <select className="form-select select-lg select-country" id="paymentMethod" onChange={showInputs.bind(this)}>
            <option value="" disabled selected>Select Payment Method</option>
            <option>Demo eCheck ...ending in 3333</option>
            <option>Visa ...ending in 4444</option>

            <option disabled>-----------------------------------------------------</option>

            <option>Add New eCheck</option>
            <option>Add New Credit Card</option>
          </select>
        </div>

        <div id="newEcheck" style={{display: 'none'}}>
          <div className='form-group'>
            <label className='form-label input-med'>Account Nickname</label>
            <input className='form-input input-med'/>
          </div>

          <div className='separate-items form-group'>
            <div>
              <label className='form-label input-med'>Routing Number</label>
              <input className='form-input input-med'/>
            </div>
            <div>
              <label className='form-label input-med'>Account Number</label>
              <input className='form-input input-med'/>
            </div>
          </div>
        </div>

        <div id="newCreditCard" style={{display: 'none'}}>
          {/*<div className='form-group'>
            <label className='form-label input-med'>Account Nickname</label>
            <input className='form-input input-med'/>
          </div>

          <div className='form-group'>
            <label className='form-label input-med'>Full Name <span>(as it appears on credit card)</span></label>
            <input className='form-input input-med'/>
          </div>

          <div className='separate-items form-group'>
            <div>
              <label className='form-label input-med'>Billing Street Address</label>
              <input className='form-input input-med'/>
            </div>
            <div>
              <label className='form-label input-med'>Billing Zip Code</label>
              <input className='form-input input-med'/>
            </div>
          </div>

          <div className='form-group'>
            <label className='form-label input-med'>Credit Card Number <span>(no dashes or spaces)</span></label>
            <input className='form-input input-med'/>
          </div>*/}
          <img src={ccInputs}></img>

        </div>
      </div>

      <div className="ConfirmAndPay">
        <button className="BtnConfirmAndPay">Confirm And Pay</button>
      </div>

      <button className="BtnCancel">
        <Link to="/Invoices/OpenInvoice/">Cancel</Link>
      </button>

      <div className="Disclaimer">By selecting (Confirm and Pay) I allow Echo to initiate this payment for the amount specified above and apply the payment to the selected invoices. Also, I accept <span>Echo's Terms and Conditions</span>on additional charges if carrier cost has been increased from unexpected delivery events.</div>
    </InvoicePaymentSummaryTwo>
  )
}

function showInputs(){
  if( document.getElementById('paymentMethod').value == 'Add New eCheck' ){
    document.getElementById('newEcheck').style.display = 'block';
    document.getElementById('newCreditCard').style.display = 'none';
  } else if ( document.getElementById('paymentMethod').value == 'Add New Credit Card' ){
    document.getElementById('newEcheck').style.display = 'none';
    document.getElementById('newCreditCard').style.display = 'block';
  } else {
    document.getElementById('newEcheck').style.display = 'none';
    document.getElementById('newCreditCard').style.display = 'none';
  }
}

export default InvoicePageComponent;