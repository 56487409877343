import React from 'react'
import styled from 'styled-components'

// Import Page Level Components
import InputSelectTemplate from '../../../InputComponents/InputSelectTemplate.js'
import InputDropdownInvoices from '../../../InputComponents/InputDropdownInvoices.js'
import TableHeaderInvoicePaid from '../../Misc/TableHeaderInvoicePaid.js'

import AccordionComponentInvoiceStaticChecked from '../../../AccordionComponents/AccordionComponentInvoiceStaticChecked.js'
import InvoicePaymentSummaryTwo from '../../../PageComponents/Invoice/InvoicePaymentSummaryTwo.js'

// Import Font Awesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faShareSquare } from '@fortawesome/free-solid-svg-icons'

library.add(faShareSquare)


const GContentInvoiceOpenTwo = styled.div`
  body {
    @import url('https://fonts.googleapis.com/css?family=Teko');
    font-family: 'Teko', sans-serif;
  }
`;

const SgContentInvoiceOpen = styled.div`
  // 📰
  margin: 0 auto;
  max-width: 1360px;
  display: grid;
  grid-template-rows: minmax(0.2fr, 5fr) 0.5fr 0.5fr 5fr;
  grid-template-columns: 0.75fr 0.25fr;
  grid-template-areas:
  "g_filters g_side_nav"
  "g_table_header g_side_nav"
  "g_shipment_table g_side_nav";
`;

const GFilters = styled.div`
  grid-area: g_filters; // 🗺
  margin-top: 15px;
  margin-bottom: 15px;
`;

const ExportResults = styled.div`
  grid-area: sg_filter_3; // 🗺
  align-self: end;
  justify-self: end;
  text-align: right;
  color: #666;
  font-weight: 600;
  font-size: 18px;
  a {
    color: #0033a1 !important;
    text-decoration: none;
    cursor: pointer;
    font-size: 14px;
    font-weight: 300;
  }
`;

const SgFilters = styled.div`
  margin: 0 auto;
  max-width: 1360px;
  padding-right: 15px;
  // 📰
  display: grid;
  grid-template-rows: minmax(1fr, 1fr) 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas:
  "sg_filter_1 sg_filter_2 sg_filter_3";

  .InputSelectTemplate {
    grid-area: sg_filter_1; // 🗺
  }
  .InputDropdownTemplate {
    grid-area: sg_filter_2; // 🗺
    justify-self: start;
  }
`;


const GTableHeader = styled.div`
  grid-area: g_table_header; // 🗺
  margin-bottom: 5px;
  padding-right: 15px;
`;

const GQuotesTable = styled.div`
  grid-area: g_shipment_table; // 🗺
  padding-right: 15px;
`;

const SgQuotesTable = styled.div`
  margin: 0 auto;
  max-width: 1360px;
  display: grid;
  grid-template-rows: repeat(10, 0.5fr);
  grid-template-columns: 1fr;
  .AccordionComponentQuotes {
    grid-column-start: 1;
    grid-column-end: 10; // 🗺
  }
`;

const GSideNav = styled.div`
  grid-area: g_side_nav; // 🗺
  border-left: 1px solid #e7edf0;
  padding-left: 15px;
`;



const EchoShipContentGrid = (props) => {
  return (

    <GContentInvoiceOpenTwo>
      <SgContentInvoiceOpen>
        <div>
          <GFilters>
              <SgFilters>
                  <InputSelectTemplate 
                    labelTitle="Search for Paid Invoices"
                    placeholder="Search by Invoice#, PO#, Load#"
                  />
                  <InputDropdownInvoices 
                    labelTitle="Sort Paid Invoices By"
                  />
                  <ExportResults>
                  0 Open Invoices <br />
                  <a href="/">Client Statement</a> <FontAwesomeIcon icon="share-square"  size="xs" style={{ color: '#0033a1' }} />
                  </ExportResults>
              </SgFilters>
          </GFilters>

          <GTableHeader>
              <TableHeaderInvoicePaid></TableHeaderInvoicePaid>
          </GTableHeader>
          <GQuotesTable>
              <SgQuotesTable>

                  <AccordionComponentInvoiceStaticChecked />

              </SgQuotesTable>
          </GQuotesTable>
        </div>

        <GSideNav>
          <InvoicePaymentSummaryTwo></InvoicePaymentSummaryTwo>
        </GSideNav>

      </SgContentInvoiceOpen>
    </GContentInvoiceOpenTwo>



  )
}

export default EchoShipContentGrid;