import React from 'react'
import styled from 'styled-components'


// Import Page Level Components
import NavPrimary from '../../components/layouts/NavPrimary.js'
import SecondaryNav from '../../components/layouts/NavSecondary.js'
import TertiaryNavActiveInvoice from '../../components/layouts/TertiaryNav/TertiaryNavInvoice.js'
import Footer from '../../components/layouts/Footer.js'

import GContentInvoiceOpenTwo from '../../components/ContentGrids/Invoices/OpenInvoiceContentGrid2.js'

const PGInvoicePaidTwo = styled.div`
  height: 100vh;
  background-color: #fff;
  // 🖋
  font-family: 'Open Sans';
  color: #0033a1;
  // 📰
  display: grid;
  grid-template-rows: 0.1fr 0.3fr 0.2fr 5fr 0.2fr;
  grid-template-columns: 1fr;
  grid-template-areas:
    "nav_secondary"
    "nav_primary"
    "nav_tertiary"
    "g_content"
    "g_footer";

  .NavPrimary {
    grid-area: nav_primary; // 🗺
    align-self: start;
  }
  .SecondaryNav {
    grid-area: nav_secondary; // 🗺
    align-self: start;
  }
  .TertiaryNavActiveInvoicePaid {
    grid-area: nav_tertiary; // 🗺
    align-self: start;
  }
  .GContentShipment {
    grid-area: g_content; // 🗺
  }
  .Footer {
    grid-area: g_footer; // 🗺
    align-self: end;
  }
`

const PageGridComponentQuotes = () => {
  return (
    <PGInvoicePaidTwo>
      <SecondaryNav></SecondaryNav>
      <NavPrimary></NavPrimary>
      <TertiaryNavActiveInvoice></TertiaryNavActiveInvoice>


        <GContentInvoiceOpenTwo></GContentInvoiceOpenTwo>

      <Footer></Footer>
    </PGInvoicePaidTwo>
  )
}

export default PageGridComponentQuotes;